/*Tooltip*/

.deck-tooltip {
  position: fixed !important;
}

.content .water-level,
.content .last-updated,
.content .risk-rating,
.content .inundation-depth {
  display: flex;
  flex-direction: 'row';
}

.content .empty {
  padding-top: 0;
  text-align: left;
}

.content h2 {
  color: #000;
  font-size: 1.1em;
  font-weight: 700;
  margin: 0 0 8px 0;
}

.content p,
.content li {
  color: #000;
  font-size: 1em;
  font-weight: 700;
  margin: 0 0 4px 0;
}

.content .label {
  color: #999;
  margin-right: 15px;
  min-width: 80px;
}

.content .empty p {
  color: #999;
}

.content .confidence-interval,
.content .datum {
  font-size: 0.8em;
}

.inundation {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.inundation .legend {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  background: #fff;
  width: max-content;
}

.inundation .legend div {
  padding: 4px 12px;
  font-size: 0.75rem;
  text-align: center;
  font-weight: 700;
}

.sensor-bridge-legend {
  display: flex;
  flex-direction: column;
  width: fit-content;
  float: right;
  align-items: left;
  background-color: rgba(75, 75, 75, 0.8);
  /* white with 80% opacity */
  color: #f5f5f5;
  padding: 10px;
  border-radius: 10px;
  position: absolute;
  z-index: 1000;
  right: 10px;
  bottom: 70px;
}

.sensor-bridge-legend .legend-item {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.75rem;
  text-align: center;
  font-weight: 700;
}

.sensor-bridge-legend .legend-item .legend-circle-sensor {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-bottom: 5px;
  border: 1px solid #000000
}

.sensor-bridge-legend .legend-item .legend-circle-bridge {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-bottom: 5px;
  border: 2px solid #FFFF00
}

.basemap {
  position: absolute;
  background-color: #fff;
  box-shadow: 0 6px 12px 0 rgb(0 0 0 / 20%);
  bottom: 10px;
  right: 10px;
  z-index: 1000;
}

.inundation .layer button,
.basemap button {
  color: #999;
  border: none;
  background-color: #fff;
  font-size: 0.75em;
  font-weight: 700;
  padding: 4px 12px;
  font-family: 'Arimo', sans-serif;
}

.inundation .layer button+button,
.basemap button+button,
.inundation .vdatum button+button {
  border-left: 2px solid #999;
}

.inundation .layer button:hover,
.basemap button:hover {
  background-color: #f5f5f5;
  color: #000;
}

.inundation .layer button.active,
.basemap button.active {
  background-color: #f5f5f5;
  color: #000;
}

.inundation .resolution,
.inundation .vdatum {
  display: flex;
  justify-content: right;
  margin-top: 8px;
}

.inundation .resolution button,
.inundation .vdatum button {
  color: #999;
  border: none;
  background-color: #fff;
  font-size: 0.75em;
  font-weight: 700;
  padding: 4px 12px;
  font-family: 'Arimo', sans-serif;
  float: right;
}

.inundation .resolution button:hover,
.inundation .vdatum button:hover {
  background-color: #f5f5f5;
  color: #000;
}

.inundation .resolution button.active,
.inundation .vdatum button.active {
  background-color: #f5f5f5;
  color: #000;
}