.panel-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 2px 5px 2px 5px;
}

.panel-top-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 5px 2px 5px;
}