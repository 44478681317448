.flood-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 750px;
    z-index: 1000;
    margin: 15px 5px 15px 5px;
}

.flood-slider .slider-text {
    background-color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    color: black;
    border-radius: 5px;
    padding: 0px 10px 0px 10px;
}

.flood-slider .horizontal-slider {
    width: 100%;
    height: 40px;
    border: 3px solid #999;
    border-radius: 5px;
}

.flood-slider .horizontal-slider-mark {
    margin-left: 7px;
    padding: 0px 5px;
    height: 20px;
    top: 7px;
}

.flood-slider .horizontal-slider-track {
    top: 7px;
    height: 20px;
}

.flood-slider .horizontal-slider-track-0 {
    background: #0a34f1;
}

.flood-slider .horizontal-slider-track-1 {
    background: #bfeef7;
}

.flood-slider .horizontal-slider-thumb {
    top: 1px;
    width: 50px;
    height: 33px;
    line-height: 28px;
    background: #03138d;
    text-align: center;
    font-weight: bold;
    color: white;
    border: 3px solid rgb(72, 72, 72);
    border-radius: 5px;
    cursor: pointer;
}