.panel-bottom {
  min-width: 750px;
  flex: 1 1 auto;
  overflow-y: scroll;
  background-color: #fff;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.panel-bottom .content {
  margin: 10px 50px 10px 50px;
  /*border-top: 1px solid #999;*/
}

.panel-bottom .content .label {
  margin-right: 32px;
}

.panel-bottom .group {
  display: flex;
}

.panel-bottom .group + .group {
  margin-top: 8px;
}

.panel-bottom .chart {
  height: 340px;
}

.panel-bottom .status + .chart {
  margin-top: 20px;
  margin-bottom: 48px;
}

.panel-bottom .risk-ratings ul {
  padding: 0;
  list-style: none;
}

.panel-bottom .risk-ratings .color {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-right: 8px;
}

.modal-60h {
  height: 80vh;
}

.modal-title {
  color: #000;
  font-family: 'Arimo', sans-serif;
  font-weight: 700 !important;
}

.tab-selection{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tab-selection-button{
  background-color: #bfeef7;
  color: #000;
  margin: 10px 1px 0px 1px;
  border: 2px solid #999;
  border-radius: 5px;
}

.tab-selection-button-active{
  background-color: #03138d;
  color: #fff;
  margin: 10px 1px 0px 1px;
  border: 3px solid rgb(72, 72, 72);
  border-radius: 5px;
}