.panel-body {
  flex: 1 1 auto;
  overflow-y: scroll;
}

.panel-body .content {
  padding: 24px 28px;
  margin: 0 0 0 0;
  /*border-top: 1px solid #999;*/
}

.panel-body .content .label {
  margin-right: 32px;
}

.panel-body .group {
  display: flex;
}

.panel-body .group+.group {
  margin-top: 8px;
}

.panel-body .chart {
  height: 340px;
}

.panel-body .status+.chart {
  margin-top: 20px;
  margin-bottom: 48px;
}

.panel-body .risk-ratings ul {
  padding: 0;
  list-style: none;
}

.panel-body .risk-ratings .color {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-right: 8px;
}

.modal-60h {
  height: 80vh;
}

.modal-title {
  color: #000;
  font-family: 'Arimo', sans-serif;
  font-weight: 700 !important;
}