h2 + .status,
.status + .status {
  margin: 20px 0 0 0;
}

.status .bridge-name {
  margin: 0;
  color: #000;
  font-size: 1em;
}

.status .num-events {
  margin: 0 0 4px 0;
  color: #999;
  font-size: 0.8em;
}

.status .status-chart {
  margin: 0;
}

.status .status-bar {
  cursor: pointer;
}

.status-chart rect:hover {
  fill: #5e6c84;
}

.tooltip-inner {
  font-size: 0.8em;
  text-align: left;
}

.status a:hover {
  text-decoration: none;
}
