.panel-header {
  background-color: #f5f5f5;
  padding: 16px 28px 10px 28px;
}

.panel-header a:hover {
  text-decoration: none;
}

.panel-header .panel-nav {
  display: flex;
  justify-content: space-between;
}

.panel-header .label {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.panel-header .label i {
  margin-right: 8px;
}

.panel-header .title {
  margin-top: 5px;
}

.panel-header .layers form {
  background-color: #fff;
  padding: 8px 16px;
  width: 50%;
  margin-bottom: 15px;
  border-radius: 5px;
}

.panel-header .layers .form-group {
  margin-bottom: 0;
}

.panel-header .layers .form-check {
  margin-bottom: 4px;
}

.panel-header .layers form label {
  color: #000;
  font-weight: 700;  
  margin-left: 8px;
}


/* Intro.js */
.btn-icon {
  border: none;
  background-color: transparent;
  color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-icon:hover {
  color: #000;
  cursor: pointer;
}

.introjs-tooltip {
  border-radius: 0;
}

.introjs-tooltipReferenceLayer * {
  color: #000;
  font-family: 'Arimo', sans-serif;
}

.introjs-tooltipReferenceLayer h5 {
  font-weight: 700;
}

.introjs-tooltipReferenceLayer p,
.introjs-tooltipReferenceLayer li {
  font-size: 0.9em !important;
  font-weight: 400 !important;
}

.introjs-tooltipReferenceLayer .green {
  color: rgba(67, 185, 130, 204);
}

.introjs-tooltipReferenceLayer .orange {
  color: rgba(255, 153, 0, 204);
  
}

.introjs-tooltipReferenceLayer .red {
  color: rgba(255, 1, 2, 204);
  
}

.introjs-tooltipReferenceLayer .purple { 
  color: rgba(204, 51, 205, 204);
}

.introjs-tooltipReferenceLayer .blue {
  color: rgba(39, 110, 241, 204);
}

.introjs-tooltipReferenceLayer .grey {
  color:  rgba(175, 175, 175, 204);
}

.introjs-tooltipReferenceLayer ul {
  margin: 0;
}

.introjs-tooltiptext {
  padding: 8px 16px;
}

.introjs-button {
  color: #000;
  background-color: transparent;
  outline: none;
  border-radius: 0;
  border: 2px solid #999;
  font-family: 'Arimo', sans-serif;
  font-weight: 700;
}

.introjs-button:hover {
  background-color: #f5f5f5;
}
