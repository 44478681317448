.opacity-slider {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    z-index: 1000;
    right: 10px;
    margin: 10px 0px 0px 0px;
}

.opacity-slider .slider-text {
    width: 70px;
    height: 25px;
    color: #484848;
    background-color: #fff;
    font-size: 0.75em;
    font-weight: 700;
    padding: 3px 10px;
    font-family: 'Arimo', sans-serif;
    float: left;
    margin: 2px 5px 3px 0px;
}

.opacity-slider .horizontal-slider {
    width: 200px;
    height: 30px;
    float: right;
}

.opacity-slider .horizontal-slider-mark {
    margin-left: 7px;
    padding: 0px 5px;
    font-size: 0.75em;
    font-weight: 500;
    height: 20px;
    top: 5px;
}

.opacity-slider .horizontal-slider-track {
    top: 5px;
    height: 17px;
}

.opacity-slider .horizontal-slider-track-0 {
    background: #0a34f1;
}

.opacity-slider .horizontal-slider-track-1 {
    background: #bfeef7;
}

.opacity-slider .horizontal-slider-thumb {
    top: 1px;
    width: 40px;
    height: 25px;
    line-height: 21px;
    background: #03138d;
    text-align: center;
    font-size: 0.75em;
    font-weight: 700;
    color: white;
    border: 3px solid rgb(72, 72, 72);
    border-radius: 5px;
    cursor: pointer;
}