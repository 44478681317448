h1 {
    font-size: 100px;
    font-weight: 800;
    text-align: center;
    font-family: 'Arimo', sans-serif;
}

h2 {
    font-size: 12px;
    text-align: center;
    font-family: 'Arimo', sans-serif;
    margin-top: 25px;
}

p {
    text-align: center;
    font-family: 'Arimo', sans-serif;
    font-size: 12px;
}

.container {
    width: 500px;
    margin: 0 auto;
    margin-top: 15%;
}