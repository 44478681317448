html,
body,
#root,
#root .app {
  height: 100%;
  font-family: 'Arimo', sans-serif;
  margin: 0;
}

h1 {
  color: #000 !important;
  font-size: 1.5em !important;
  font-weight: 700 !important;
  margin: 0;
}

h2 {
  color: #000 !important;
  font-size: 1.3em !important;
  font-weight: 700 !important;
  margin: 0;
}

p {
  color: #000;
  font-size: 1em !important;
  font-weight: 700 !important;
  margin: 0;
}

.label {
  color: #999;
}

.layers {
  margin-top: 16px;
}

.side-panel {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 400px;
  z-index: 1000;

  background-color: #fff;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  max-height: 96%;
  overflow-y: hidden;
  opacity: 0.95;
}

.top-panel {
  position: absolute;
  top: 10px;
  max-width: 750px;
  left: 40%;
  min-height: 50px;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.95;
}

.bottom-panel {
  position: absolute;
  bottom: 10px;
  max-width: 1000px;
  min-width: 750px;
  max-height: 75%;
  left: 25%;
  min-height: 100px;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.95;
}

.update-bar{
  position: absolute;
  z-index: 1000;
  bottom: 10px;
  left: 40%;
  background-color: #f5f5f5;
  box-shadow: 0 6px 12px 0 rgb(0 0 0 / 20%);
  display: flex;
  align-items: center;
  padding: 0px 5px 0px 5px;
}